
























































































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      loading: false,
      selection: 1,
      servicos: ["Support", "Insights", "Fly", "outros"],
      servico_selecionado: "Support",
      videos_yt: [
        "https://www.youtube.com/embed/ONs3KKeNU8E",
        "https://www.youtube.com/embed/1PWFnf_VOn0",
        "https://www.youtube.com/embed/QEyT7CZLus0",
      ],
      suporte: {
        formatacao: false,
        instalacao: false,
      },
    };
  },
  methods: {
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
  },
});
